import React from "react";
import {CampaignData} from "../../lib/transform-json";
import {ActionTooltip} from "../../components/shared/tooltip";
import {Avatar, AvatarFallback, AvatarImage} from "../../components/shared/avatar";
import {getDateByLocale} from "../../lib/utils";
import {useLanguage} from "../../contexts/LanguageContext";
import {MoreVertical, X} from "lucide-react";
import {useToast} from "../../contexts/ToastContext";

interface CampaignItemProps {
    campaignData: CampaignData;
    onClick?: (id: string) => void;
    onDelete?: (id: string) => void;
}

const CampaignItem: React.FC<CampaignItemProps> = ({campaignData, onClick, onDelete}) => {

    const {language} = useLanguage();
    const {translate} = useLanguage();
    const {add} = useToast();

    const handlerCopy = (value: string) => {
        navigator.clipboard.writeText(value).then(() => {
            add({message: translate('apps.app-id-copied'), type: 'success'});
        }).catch(() => {
            add({message: translate('error.copy'), type: 'error'});
        });
    }

    return (
        <div
            className={`flex flex-col w-full rounded-md overflow-hidden border border-gray-border dark:border-gray-dark-border`}>
            <div
                className={`flex flex-row w-full ${campaignData.status === 'PENDING' ? 'bg-gray/10' : (campaignData.status === 'IN_PROGRESS' ? "bg-orange/10" : "bg-green/10")} justify-between items-center p-2`}>

                <div className={"flex flex-row gap-2 items-center"}>

                    <span title={campaignData.app.details[`${campaignData.locale.country}_${campaignData.locale.language}`].name}
                          className={"max-w-[2000px] whitespace-nowrap truncate font-bold text-gray-dark dark:text-white"}>
                                {campaignData.app.details[`${campaignData.locale.country}_${campaignData.locale.language}`] ?
                                    campaignData.app.details[`${campaignData.locale.country}_${campaignData.locale.language}`].name :
                                    'Undefined'
                                }
                            </span>

                    <ActionTooltip
                        label={translate('campaigns.my.country-for-campaign', {locale: campaignData.locale.name})}>
                        <img
                            className={`h-4 w-4`}
                            alt={campaignData.locale.country.toUpperCase()}
                            src={`https://purecatamphetamine.github.io/country-flag-icons/3x2/${campaignData.locale.country.toUpperCase()}.svg`}/>
                    </ActionTooltip>
                </div>

                <div className={"flex flex-row gap-2"}>
                    {onDelete && (
                        <ActionTooltip label={translate('def.delete')}>
                            <div
                                className={"cursor-pointer flex p-1 hover:bg-red/30 transition-all rounded-md"}
                                onClick={() => onDelete && campaignData && campaignData.id && onDelete(campaignData.id)}>
                                <X className={"text-gray-dark dark:text-white"} size={15}/>
                            </div>
                        </ActionTooltip>
                    )}

                    {onClick && (
                        <ActionTooltip label={translate('campaigns.my.open-detail')}>
                            <div
                                className={"cursor-pointer flex p-1 hover:bg-green/30 transition-all rounded-md"}
                                onClick={() => onClick && onClick(campaignData.id || '')}>
                                <MoreVertical className={"text-gray-dark dark:text-white"} size={15}/>
                            </div>
                        </ActionTooltip>
                    )}
                </div>
            </div>
            <div className={"flex flex-row gap-2 p-2 jus"}>

                <Avatar className={`h-20 w-20 transition-all`}>
                    <AvatarFallback delayMs={500} className={campaignData.app.status === 'PENDING' ? 'bg-blue/60 dark:bg-blue-dark' : ''}>
                        {campaignData.app.details[`${campaignData.locale.country}_${campaignData.locale.language}`] ?
                            campaignData.app.details[`${campaignData.locale.country}_${campaignData.locale.language}`].name.charAt(0).toUpperCase() :
                            'Undefined'}
                    </AvatarFallback>
                    <AvatarImage
                        src={campaignData.app.details[`${campaignData.locale.country}_${campaignData.locale.language}`] ?
                            campaignData.app.details[`${campaignData.locale.country}_${campaignData.locale.language}`].image_src :
                            ''}
                    />
                </Avatar>

                <div className={"flex flex-col gap-2 justify-center"}>
                    <div className={"flex flex-row items-start"}>
                        <span className={"text-gray/60 text-xs"}>{translate('def.status')}:</span>
                        <span
                            className={"text-gray-dark dark:text-white text-xs font-bold ml-1"}>{campaignData.status}</span>
                    </div>
                    <div className={"flex flex-row items-start"}>
                        <span className={"text-gray/60 text-xs"}>{translate('campaigns.my.days')}:</span>
                        <div className={"flex flex-row gap-1"}>
                            {campaignData.days.map((day, index) => (
                                <span
                                    key={index}
                                    className={`${day.status === 'PENDING' ? "text-gray" : (day.status === 'IN_PROGRESS' ? "text-orange" : "text-green")} text-xs font-bold ml-1`}>{day.day}</span>
                            ))}
                        </div>
                    </div>
                    <div className={"flex flex-row items-start"}>
                        <span className={"text-gray/60 text-xs"}>{translate('campaigns.my.app-id')}:</span>
                        <span
                            onClick={() => handlerCopy(campaignData.app.app_id)}
                            className={"text-gray-dark dark:text-white text-xs font-bold ml-1 cursor-pointer"}>
                                {campaignData.app.app_id}
                            </span>
                    </div>
                </div>
            </div>

            <div className={"flex flex-row bg-gray/10 justify-between items-center p-2"}>
                <ActionTooltip label={translate('campaigns.my.date_create')}>
                    <div
                        className={"cursor-pointer flex flex-row items-center gap-1 p-1"}>
                            <span
                                className={"text-xs font-bold text-gray-dark dark:text-white"}>{getDateByLocale(campaignData.create_date, language)}</span>
                    </div>
                </ActionTooltip>
                <ActionTooltip label={translate('campaigns.my.date_update')}>
                    <div
                        className={"cursor-pointer flex flex-row items-center gap-1 p-1"}>
                        <span
                            className={"text-xs font-bold text-gray-dark dark:text-white"}>
                            {getDateByLocale(campaignData.update_date, language)}
                        </span>
                    </div>
                </ActionTooltip>
            </div>
        </div>
    );
}

const ItemCampaignCardLoader = () => {
    return (
        <div role="status"
             className={"flex flex-col w-full rounded-md border border-gray-border dark:border-gray-dark-border"}>
            <div className={"flex flex-row bg-gray/10 justify-between items-center p-2"}>
                <div className={"h-3 w-36 bg-gray/50 rounded-full animate-[pulse_1.5s_infinite]"}></div>
            </div>
            <div className={"flex flex-row gap-2 p-2"}>
                <div className={"flex flex-col items-start gap-1"}>
                    <div className={"h-2 w-28 bg-gray/50 rounded-full animate-[pulse_1.5s_infinite]"}></div>
                    <div className={"h-2 w-36 bg-gray/50 rounded-full animate-[pulse_1.5s_infinite]"}></div>
                    <div className={"h-2 w-28 bg-gray/50 mt-2 rounded-full animate-[pulse_1.5s_infinite]"}></div>
                    <div className={"h-2 w-36 bg-gray/50 rounded-full animate-[pulse_1.5s_infinite]"}></div>
                </div>
            </div>
        </div>
    )
}

export {CampaignItem, ItemCampaignCardLoader}
