import React from "react";

type SwitchProps = {
    id?: string;
    label?: string;
    className?: string;
    value?: boolean;
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    disable?: boolean
}

const Switch: React.FC<SwitchProps> = ({...props}) => {

    return (
        <label className={`relative inline-flex items-center ${props.disable ? 'cursor-no-drop' : 'cursor-pointer'}`}>
            <input id={props.id} type="checkbox" value="" checked={props.value} className="sr-only peer" onChange={props.onChange}
                   disabled={props.disable}/>
            <div
                className="w-9 h-5 bg-gray/20 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-green/30 dark:peer-focus:ring-green/80 rounded-full peer dark:bg-gray/70 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray/30 after:border after:rounded-full after:h-4 after:w-4 after:transition-all dark:border-gray/60 peer-checked:bg-green/60"></div>
            {props.label && (
                <span className="ml-3 text-sm font-medium text-gray/90 dark:text-gray/30">{props.label}</span>
            )}
        </label>
    )
}

export {Switch}