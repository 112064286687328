import React, {useCallback, useEffect, useState} from "react";
import {Select, SelectContent, SelectItem, SelectTrigger, SelectValue} from "../../components/shared/select";
import {ActionTooltip} from "../../components/shared/tooltip";
import {RefreshCwIcon} from "lucide-react";
import {Card} from "../../components/shared/card";
import {CampaignData, transformJsonToCampaignDataList} from "../../lib/transform-json";
import useAxiosPrivate from "../../hooks/use-axios-private";
import {Avatar, AvatarFallback, AvatarImage} from "../../components/shared/avatar";
import {useLanguage} from "../../contexts/LanguageContext";
import {ChartData, LineChart, LineData} from "../../components/shared/ag-chart";
import {getDateByLocale} from "../../lib/utils";
import {useAuth} from "../../contexts/AuthContext";
import {Switch} from "../../components/shared/switch";

type InstallMonitorProps = {
    onChangeCampaign: (campaign: CampaignData | null) => void;
    onUpdate?: () => void;
}

export const InstallMonitor: React.FC<InstallMonitorProps> = ({onChangeCampaign, onUpdate}) => {
    const axiosPrivate = useAxiosPrivate();
    const {translate, language} = useLanguage();
    const {user} = useAuth();

    const chartTypes = ["ALL", "CURRENT_DAY"];

    const [status, setStatus] = useState<string>("IN_PROGRESS");
    const [campaigns, setCampaigns] = useState<CampaignData[]>([]);
    const [campaign, setCampaign] = useState<CampaignData | null>(null);
    const [chartType, setChartType] = useState<string>(chartTypes[0]);
    const [monitoringData, setMonitoringData] = useState<ChartData | null>(null);
    const [lastUpdateTime, setLastUpdateTime] = useState<string | null>(null);
    const [loading, setLoading] = useState({init: false, refresh: false});

    const handleSwitchStatus = useCallback(() => {
        setStatus(prevStatus => prevStatus === 'IN_PROGRESS' ? 'FINISHED' : 'IN_PROGRESS');
    }, []);

    const loadCampaigns = useCallback(() => {
        setLoading(prev => ({...prev, init: true}));

        let url = `/campaigns/list${user?.roles.includes('ADMIN') ? '/all' : ''}?status=${status}`;

        axiosPrivate.get(url)
            .then(response => {
                if (response.status === 200 && response.data.data) {
                    setCampaigns(transformJsonToCampaignDataList(response.data.data));
                    setCampaign(null); // Сбрасываем выбранную кампанию
                }
            })
            .catch(console.log)
            .finally(() => setLoading(prev => ({...prev, init: false})));
    }, [axiosPrivate, status, user]);

    const handleRefresh = useCallback(() => {
        if (campaign != null && !loading.init && !loading.refresh) {
            onUpdate && onUpdate();
            loadMonitoring();
        }
    }, [campaign, loading]);

    const loadMonitoring = useCallback(() => {
        if (campaign == null) {
            setMonitoringData(null);
            return;
        }

        setLoading(prev => ({...prev, refresh: true}));

        axiosPrivate.post(`/statistic/campaign`, {
            cuid: campaign.id,
            type: chartType,
        })
            .then(response => {
                if (response.status === 200 && response.data.data) {
                    let responseDataCategories = response.data.data.categories;
                    let responseDataSeries = response.data.data.series;

                    const keyMap = new Map<string, string>();

                    const generateKey = (originalKey: string): string => {
                        // Если ключ уже существует, возвращаем его
                        if (keyMap.has(originalKey)) {
                            return keyMap.get(originalKey)!;
                        }

                        // Если ключа нет, создаём новый
                        let counter = 1;
                        let uniqueKey = `key_${counter}`;

                        while (Array.from(keyMap.values()).includes(uniqueKey)) {
                            counter++;
                            uniqueKey = `key_${counter}`;
                        }

                        // Сохраняем новый ключ в Map
                        keyMap.set(originalKey, uniqueKey);

                        return uniqueKey;
                    };

                    let result: ChartData = {
                        data: [],
                        series: []
                    }

                    responseDataCategories.forEach((time: number) => {
                        let existingItem = result.data.find((item: LineData) => item.dateTime === time);

                        if (!existingItem) {
                            result.data.push({dateTime: time});
                            existingItem = result.data[result.data.length - 1]; // Получаем только что добавленный элемент
                        }

                        Object.keys(responseDataSeries).forEach((key) => {
                            const uniqueKey = generateKey(key);

                            // Добавляем данные
                            existingItem!![uniqueKey] =
                                responseDataSeries[key].find((v: { time_check: number }) => v.time_check === time)?.value ?? 0;
                        });
                    });

                    result.data.forEach((item) => {
                        // Суммируем все числовые значения
                        item.total = Object.entries(item)
                            .filter(([key]) => key !== "dateTime" && key !== "total") // Исключаем dateTime и total
                            .reduce((sum, [_, value]) => sum + (value as number), 0);

                        //Добавляем серии
                        Object.keys(item).forEach((key) => {
                            if (key !== "dateTime") {
                                let existingSeries = result.series.find((series) => series.yKey === key);

                                if (!existingSeries) {

                                    let originalName = Array.from(keyMap.entries()).find(([_, value]) => value === key)?.[0] || key;

                                    result.series.push({
                                        type: 'line',
                                        xKey: 'dateTime',
                                        yKey: key,
                                        yName: key === 'total' ? 'Total' : originalName.split('$$').length > 1 ? originalName.split('$$')[0] + ' - ' + originalName.split('$$')[1] : originalName,
                                        interpolation: {type: 'smooth'},
                                        visible: key === 'total',
                                    });
                                }
                            }
                        });
                    });

                    // Устанавливаем данные
                    setLastUpdateTime(response.data.data.time_update);
                    setMonitoringData(result);
                }
            })
            .catch(console.log)
            .finally(() => setLoading(prev => ({...prev, refresh: false})));
    }, [axiosPrivate, campaign, chartType]);

    useEffect(() => {
        if (status === 'FINISHED') {
            setChartType("ALL");
        }
        loadCampaigns();
    }, [status, loadCampaigns]);

    useEffect(() => {
        loadMonitoring();
    }, [campaign, chartType, loadMonitoring]);

    useEffect(() => {
        onChangeCampaign(campaign);
    }, [campaign, status]);

    return (
        <Card>
            <div className={"flex justify-start"}>
              <span className="font-bold text-xl text-gray-dark dark:text-white">
                    {translate('dashboard.statistic.title')}
              </span>
            </div>

            <div className="flex w-full xs:items-start xs:flex-col sm:flex-col md:flex-row lg:flex-row gap-2 justify-between">
                <div className="flex w-full h-fit xs:items-start md:items-center xs:flex-col sm:flex-col md:flex-row lg:flex-row gap-2 justify-center">
                    <div className="flex w-72">
                        <Select
                            disabled={loading.init || loading.refresh}
                            onValueChange={(value) => setCampaign(campaigns.find(c => c.id === value) || null)}
                            value={campaign?.id || ""}
                        >
                            <SelectTrigger
                                className="h-16 bg-green/50 border-0 focus:ring-0 text-gray-dark font-bold dark:text-white capitalize">
                                <SelectValue placeholder={translate('campaigns.my.select-campaign')}/>
                            </SelectTrigger>

                            <SelectContent>
                                <div className={"h-fit max-h-60 overflow-scroll"}>
                                    {campaigns.length === 0 ? (
                                        <span className="text-xs text-gray-dark dark:text-white font-bold flex items-center justify-center">
                                        {translate('campaigns.my.no-found-campaigns')}
                                    </span>
                                    ) : (
                                        campaigns.map(c => (
                                            <SelectItem
                                                key={c.id}
                                                value={c.id!!}
                                                className={`cursor-pointer font-bold capitalize hover:bg-green/40 rounded-md text-xs transition-all`}
                                            >
                                                <div className={"flex flex-row gap-2"}>
                                                    {c.app.status === 'ACTIVE' && (
                                                        <>
                                                            <div className="relative">
                                                                <Avatar className="h-12 w-12 transition-all">
                                                                    <AvatarFallback delayMs={500}>I</AvatarFallback>
                                                                    <AvatarImage
                                                                        src={c.app.details[`${c.locale.country}_${c.locale.language}`].image_src}
                                                                    />
                                                                </Avatar>

                                                                <img
                                                                    className="absolute bottom-0 right-0 h-4 w-6 rounded-md overflow-hidden shadow-md"
                                                                    alt={c.locale.country.toUpperCase()}
                                                                    src={`https://purecatamphetamine.github.io/country-flag-icons/3x2/${c.locale.country.toUpperCase()}.svg`}
                                                                />
                                                            </div>
                                                            <div className={"flex flex-col items-start justify-center"}>
                                                                <span
                                                                    className={"max-w-[180px] whitespace-nowrap truncate"}>
                                                                    {c.app.details[c.locale.country + '_' + c.locale.language].name}
                                                                </span>
                                                                <span
                                                                    className={"text-gray-dark/50 dark:text-white/70 max-w-[200px] whitespace-nowrap truncate"}>
                                                                    {c.app.details[c.locale.country + '_' + c.locale.language] ?
                                                                        c.app.details[c.locale.country + '_' + c.locale.language].publisher :
                                                                        c.app.details['us_en'].publisher}
                                                                </span>
                                                                {c.user?.username !== user?.username && (
                                                                    <span
                                                                        className={"text-xs text-gray-dark/50 dark:text-white/70 max-w-[200px] whitespace-nowrap truncate"}>
                                                                        {c.user?.username}
                                                                    </span>
                                                                )}
                                                            </div>
                                                        </>
                                                    )}
                                                    {c.app.status === 'PENDING' && (
                                                        <>
                                                            <div className="relative">
                                                                <Avatar className={`h-12 w-12 transition-all`}>
                                                                    <AvatarFallback delayMs={500} className={"bg-blue/60 dark:bg-blue-dark"}>
                                                                        T
                                                                    </AvatarFallback>
                                                                    <AvatarImage
                                                                        src={'https://via.placeholder.com/150'}
                                                                    />
                                                                </Avatar>

                                                                <img
                                                                    className="absolute bottom-0 right-0 h-4 w-6 rounded-md overflow-hidden shadow-md"
                                                                    alt={c.locale.country.toUpperCase()}
                                                                    src={`https://purecatamphetamine.github.io/country-flag-icons/3x2/${c.locale.country.toUpperCase()}.svg`}
                                                                />
                                                            </div>
                                                            <div className={"flex flex-col items-start justify-center"}>
                                                                <span
                                                                    className={"max-w-[200px] whitespace-nowrap truncate"}>
                                                                    TESTING
                                                                </span>
                                                                <span
                                                                    className={"text-gray-dark/50 dark:text-white/70 max-w-[200px] whitespace-nowrap truncate"}>
                                                                    {c.app.app_id}
                                                                </span>
                                                                {c.user?.username !== user?.username && (
                                                                    <span
                                                                        className={"text-xs text-gray-dark/50 dark:text-white/70 max-w-[200px] whitespace-nowrap truncate"}>
                                                                        {c.user?.username}
                                                                    </span>
                                                                )}
                                                            </div>
                                                        </>
                                                    )}
                                                </div>
                                            </SelectItem>
                                        ))
                                    )}
                                </div>
                            </SelectContent>
                        </Select>
                    </div>

                    <div className="flex w-48">
                        <Select disabled={loading.init || loading.refresh || status === 'FINISHED'} onValueChange={setChartType} defaultValue={chartTypes[0]}
                                value={chartType}>
                            <SelectTrigger className="bg-green/50 border-0 focus:ring-0 text-gray-dark font-bold dark:text-white capitalize h-12">
                                <SelectValue placeholder="Chart type"/>
                            </SelectTrigger>
                            <SelectContent>
                                {chartTypes.map(type => (
                                    <SelectItem key={type} value={type} className="cursor-pointer font-bold capitalize hover:bg-green/40 rounded-md text-xs">
                                        {type}
                                    </SelectItem>
                                ))}
                            </SelectContent>
                        </Select>
                    </div>
                    <div className={"flex"}>
                        <ActionTooltip label={translate('campaigns.my.switch-status', {statuses: "IN_PROGRESS / FINISHED"})}>
                            <Switch value={status === 'FINISHED'} onChange={handleSwitchStatus} disable={loading.init || loading.refresh}/>
                        </ActionTooltip>
                    </div>
                </div>

                <div className="flex items-center gap-1 md:flex-row min-w-44 justify-end xs:flex-row-reverse">
                    <ActionTooltip label={translate('def.last-update-time')}>
                        <span className="text-xs text-gray/50">{lastUpdateTime ? getDateByLocale(lastUpdateTime, language) : translate('def.no-data')}</span>
                    </ActionTooltip>
                    <ActionTooltip label={translate('def.refresh')}>
                        <div className="p-2 hover:bg-green/20 rounded-md text-gray-dark dark:text-white" onClick={handleRefresh}>
                            <RefreshCwIcon size={20} className={`${loading.refresh ? 'animate-spin' : ''}`}/>
                        </div>
                    </ActionTooltip>
                </div>
            </div>

            <LineChart chartData={monitoringData} loading={loading.refresh} typeDate={chartType === 'ALL' ? 'withoutHour' : 'withHour'}/>
        </Card>
    );
};
