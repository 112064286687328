import {useLanguage} from "../../contexts/LanguageContext";
import {Card} from "../../components/shared/card";
import {InstallMonitor} from "./install-monitor";
import React, {useEffect, useState} from "react";
import {CampaignData} from "../../lib/transform-json";
import useAxiosPrivate from "../../hooks/use-axios-private";
import {StatisticDayItem, StatisticItemDayCardLoader} from "./statistic-day-item";

export default function DashboardScene() {

    const {translate} = useLanguage();
    const axiosPrivate = useAxiosPrivate();

    const [loading, setLoading] = useState<boolean>(false);

    const [campaignSelect, setCampaignSelect] = useState<CampaignData | null>(null);
    const [response, setResponse] = useState<CampaignData | null>(null);

    const loadStatistics = () => {
        setLoading(true);
        axiosPrivate.get(`/campaigns/statistic?cuid=${campaignSelect?.id}`)
            .then((response) => {
                if (response.status === 200 && response.data.data) {
                    console.log(response.data.data);
                    setResponse(response.data.data);
                }
            })
            .catch((error) => {
                console.error('Error fetching statistics:', error);
            })
            .finally(() => setLoading(false));
    }

    useEffect(() => {
        if (campaignSelect) {
            loadStatistics();
        } else {
            setResponse(null);
        }
    }, [campaignSelect]);

    return (
        <div className="flex flex-col gap-4">
            <span className="text-2xl font-bold text-gray-dark dark:text-white">{translate('dashboard.title-page')}</span>
            <div className="flex flex-row">
                <InstallMonitor onChangeCampaign={(campaign) => setCampaignSelect(campaign)} onUpdate={loadStatistics}/>
            </div>
            <div className="flex flex-row">
                <Card title={"Statistics by days"}>
                    <div className={`grid ${(!response || response.days.length === 0) && !loading ? "grid-cols-1" : "lg:grid-cols-4 sm:grid-cols-1 md:grid-cols-3"} gap-2`}>
                        {!loading && response && response.days.length > 0 && (
                            response.days.map((day, index) => (
                                <StatisticDayItem
                                    key={"day_" + index}
                                    dayData={day}
                                />
                            ))
                        )}

                        {(!response || response.days.length === 0) && !loading && (
                            <>
                                <div className={"flex flex-col gap-2"}>
                                    <span className={"text-lg font-bold text-gray-dark dark:text-white"}>No data</span>
                                </div>
                            </>
                        )}

                        {loading && (
                            <>
                                <StatisticItemDayCardLoader/>
                                <StatisticItemDayCardLoader/>
                                <StatisticItemDayCardLoader/>
                                <StatisticItemDayCardLoader/>
                            </>
                        )}
                    </div>
                </Card>
            </div>
        </div>
    );
}
