import {KeywordData} from "../../lib/transform-json";
import React, {useState} from "react";
import {Select, SelectContent, SelectItem, SelectTrigger, SelectValue} from "../../components/shared/select";
import {ChartColumnIncreasing} from "lucide-react";
import {Separator} from "../../components/shared/separator";
import {useLanguage} from "../../contexts/LanguageContext";

type SelectKeywordsProps = {
    keywords: KeywordData[];
    disable?: boolean;
    selectedKeyword?: string | number;
    onSelectKeyword?: (keyword: string) => void;
    onPutKeywords?: () => void;
}

const SelectKeywords: React.FC<SelectKeywordsProps> = ({keywords, selectedKeyword, onSelectKeyword, onPutKeywords, disable}) => {

    const {translate} = useLanguage();

    const [open, setOpen] = useState(false);

    return (
        <div className={"w-52"}>
            <Select
                disabled={disable}
                value={selectedKeyword?.toString() || ""}
                open={open}
                onOpenChange={setOpen}
                onValueChange={(value) => {
                    onSelectKeyword && onSelectKeyword(value);
                    setOpen(false);
                }}
            >
                <SelectTrigger
                    className={`bg-green/50 border-0 focus:ring-0 text-gray-dark font-bold dark:text-white ring-offset-0 focus:ring-offset-0 capitalize outline-none h-fit`}>
                    <SelectValue placeholder={translate('keywords.select-keywords')}/>
                </SelectTrigger>

                <SelectContent className={"flex flex-col h-fit overflow-scroll"}>
                    <div className={"flex flex-col gap-1 max-h-144 overflow-scroll"}>
                        {Object.values(keywords).map((k) => (
                            <SelectItem
                                key={k.keyword}
                                value={k.keyword}
                                className="cursor-pointer font-bold hover:bg-green/40 rounded-md text-xs w-full">
                                <div
                                    className="flex flex-row text-gray dark:text-white items-center gap-1">
                                    {k.position > 0 && (
                                        <>
                                            <ChartColumnIncreasing className={"text-blue"}
                                                                   size={16}/>
                                            <span
                                                className="max-w-[200px] whitespace-nowrap truncate">
                                                {k.position}
                                            </span>
                                        </>
                                    )}
                                    <span
                                        className={"flex flex-row lowercase"}>
                                        {k.position > 0 ? `"${k.keyword}"` : `${k.keyword}`}
                                    </span>
                                </div>

                            </SelectItem>
                        ))}
                    </div>
                    <Separator orientation={"horizontal"} className={"mt-1"}/>
                    <div
                        onClick={() => {
                            onPutKeywords && onPutKeywords();
                            setOpen(false);
                        }}
                        className={"flex text-xs text-gray-dark dark:text-white rounded-md p-1 hover:bg-green/50 transition-all items-center justify-center cursor-pointer mt-1"}>
                        <span className={"font-bold"}>{translate('keywords.put-keywords')}</span>
                    </div>
                </SelectContent>
            </Select>
        </div>
    )
}

export {SelectKeywords}
