import {CampaignDayData, CampaignTaskData} from "../../lib/transform-json";
import React from "react";
import {useLanguage} from "../../contexts/LanguageContext";
import {Separator} from "../../components/shared/separator";
import {getDateByLocale} from "../../lib/utils";
import {ProgressLine} from "../../components/shared/progress-line";

interface StatisticDayItemProps {
    dayData: CampaignDayData;
}

const StatisticDayItem: React.FC<StatisticDayItemProps> = ({dayData}) => {

    const {translate} = useLanguage();

    return (
        <div>
            <div
                className={`flex flex-col w-full rounded-md overflow-hidden border border-gray-border dark:border-gray-dark-border`}>
                <div
                    className={`flex flex-row w-full ${dayData.status === 'PENDING' ? 'bg-gray/10' : (dayData.status === 'IN_PROGRESS' ? "bg-orange/10" : "bg-green/10")} justify-between items-center p-2`}>

                    <div className={"flex flex-row gap-2 items-center"}>
                        <span
                            className={"text-gray-dark dark:text-white"}>{translate('campaigns.detail.day')} {dayData.day}
                        </span>
                    </div>
                </div>
                <div className={"flex flex-col gap-2 p-2"}>
                    <div className={"flex flex-row items-start gap-2"}>
                        <span className={"text-xs text-gray-dark dark:text-white"}>{translate('campaigns.detail.day-status')}:</span>
                        <span
                            className={`${dayData.status === 'PENDING' ? 'text-gray' : (dayData.status === 'IN_PROGRESS' ? "text-orange" : "text-green")} text-xs`}>{dayData.status}</span>
                    </div>
                    <div className={"flex flex-col items-start gap-1"}>
                        <span className={"text-xs text-gray-dark dark:text-white"}>{translate('campaigns.detail.tasks-detail')}</span>
                        <Separator orientation={"horizontal"}/>
                        <div className={"w-full grid grid-cols-2 gap-1"}>
                            {dayData.tasks.map((task, index) => (
                                <StatisticItemTask key={"day_" + dayData.day + "_task_" + index} taskData={task} statusDay={dayData.status!!}/>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

type StatisticItemTaskProps = {
    taskData: CampaignTaskData;
    statusDay: string;
}

const StatisticItemTask: React.FC<StatisticItemTaskProps> = ({taskData, statusDay}) => {

    const {translate, language} = useLanguage();

    const progress = taskData.success_count!! / taskData.count * 100;

    const bgBorderTask = statusDay === 'FINISHED' || statusDay === 'IN_PROGRESS' ? (progress >= 95 ? "border-green/50" : progress >= 50 ? "border-orange/50" : "border-red/50") : "border-gray/50";

    function timestampToDateStrWithHour(timestamp: number | Date): string {
        const date = new Date(timestamp);
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Месяцы начинаются с 0
        const hours = date.getHours().toString().padStart(2, '0');

        return `${day}.${month} ${hours}:00`;
    }

    return (
        <div className={`flex flex-col w-full rounded-md overflow-hidden border ${bgBorderTask} p-2 gap-1`}>
            <div className={"flex flex-row gap-2"}>
                <span className={"text-xs text-gray-dark dark:text-white"}>{translate('campaigns.detail.type')}:</span>
                <span className={"text-xs text-blue"}>{taskData.type}</span>
            </div>
            {Object.entries(taskData.other_params)
                .sort(([keyA], [keyB]) => {
                    // Помещаем start_time и end_time в конец, при этом start_time будет перед end_time
                    if ((keyA === "start_date" || keyA === "end_date") && !(keyB === "start_date" || keyB === "end_date")) {
                        return 1;
                    }
                    if (!(keyA === "start_date" || keyA === "end_date") && (keyB === "start_date" || keyB === "end_date")) {
                        return -1;
                    }
                    // Если оба ключа start_time и end_time, то start_time идёт перед end_time
                    if (keyA === "start_date" && keyB === "end_date") {
                        return -1;
                    }
                    if (keyA === "end_date" && keyB === "start_date") {
                        return 1;
                    }
                    return 0; // Остальные ключи остаются на своих местах
                })
                .map(([key, value]) => (
                    <div key={"param_" + taskData.id + "_" + key} className={"flex flex-row gap-2"}>
                        <span className={"text-xs text-gray-dark dark:text-white"}>
                            {translate('campaigns.detail.' + key)}:
                        </span>
                        <span className={"text-xs text-gray-dark dark:text-white truncate max-w-[80px] block"}
                              title={key.includes("date") ? "" : value.toString()}>
                            {key.includes("date") ? getDateByLocale(value, language) : value}
                        </span>
                    </div>
                ))}
            <Separator orientation={"horizontal"}/>
            <span className={"text-xs text-orange"}>Not Found: {taskData.not_found_count}</span>
            <span className={"text-xs text-gray-dark dark:text-gray"}>Prev. Install: {taskData.prev_install_count}</span>
            <span className={"font-bold text-green"}>Success: {taskData.success_count}/{taskData.count}</span>
            <Separator orientation={"horizontal"}/>
            <div className={"flex flex-row gap-2 w-full items-center"}>
                <ProgressLine progress={progress}/>
            </div>
        </div>
    );
}

const StatisticItemDayCardLoader = () => {
    return (
        <div role="status"
             className={"flex flex-col w-full rounded-md border border-gray-border dark:border-gray-dark-border"}>
            <div className={"flex flex-row bg-gray/10 justify-between items-center p-2"}>
                <div className={"h-3 w-36 bg-gray/50 rounded-full animate-[pulse_1.5s_infinite]"}></div>
            </div>
            <div className={"flex flex-row gap-2 p-2"}>
                <div className={"flex flex-col items-start gap-1"}>
                    <div className={"h-2 w-28 bg-gray/50 rounded-full animate-[pulse_1.5s_infinite]"}></div>
                    <div className={"h-2 w-28 bg-gray/50 mt-2 rounded-full animate-[pulse_1.5s_infinite]"}></div>
                    <div className={"h-2 w-36 bg-gray/50 rounded-full animate-[pulse_1.5s_infinite]"}></div>
                    <Separator orientation={"horizontal"}/>
                    <div className={"h-2 w-36 bg-gray/50 rounded-full animate-[pulse_1.5s_infinite]"}></div>
                </div>
            </div>
        </div>
    )
}

export {StatisticDayItem, StatisticItemDayCardLoader}
