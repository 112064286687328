import React from "react";
import {AlertTriangle, Download, Key, Link, RefreshCwIcon, Rocket, Tag, X} from "lucide-react";
import {Avatar, AvatarFallback, AvatarImage} from "../../components/shared/avatar";
import {ActionTooltip} from "../../components/shared/tooltip";
import {getDateByLocale} from "../../lib/utils";
import {useLanguage} from "../../contexts/LanguageContext";
import {AppData} from "../../lib/transform-json";
import {Separator} from "../../components/shared/separator";
import {RateStars} from "../../components/shared/rate-stars";

type ItemAppCardProps = {
    data: AppData;
    onUpdate?: (app: AppData) => void;
    onDelete?: (app: AppData) => void;
    onEditLocale?: (app: AppData) => void;
    onClickEditKeywords?: (app: AppData) => void;
    onClickPromote?: (app: AppData) => void;
}

const ItemAppCard: React.FC<ItemAppCardProps> = ({data, ...props}) => {

    const {language} = useLanguage()
    const {translate} = useLanguage()

    const countryApp = data.locale?.country || 'us';
    const languageApp = data.locale?.language || 'en';
    const appNameLocale = data.locale?.name || 'Unknown';

    const details = data.details!![`${countryApp}_${languageApp}`];

    if (!details) {
        return null;
    }

    return (
        <div
            className={"flex flex-col w-full rounded-md border border-gray-border dark:border-gray-dark-border overflow-hidden"}>
            <div
                className={`flex flex-row ${data.status === 'PENDING' ? 'bg-gray/10' : (data.status === 'BANNED' ? "bg-red/10" : "bg-green/10")} justify-between items-center p-2`}>
                <span
                    className={`text-lg text-gray-dark dark:text-white font-bold items-start justify-center`}>{data.status}</span>
                <div className={"flex flex-row gap-1"}>
                    {data.status === 'ACTIVE' && (
                        <div className={"flex flex-row items-center gap-1"}>
                            {props.onClickPromote && (
                                <ActionTooltip label={translate('apps.create-promote')}>
                                    <div
                                        className={"cursor-pointer flex p-1 bg-blue/30 hover:bg-blue/50 transition-all rounded-md"}
                                        onClick={() => props.onClickPromote && props.onClickPromote(data)}>
                                        <Rocket className={"text-gray-dark dark:text-white"} size={15}/>
                                    </div>
                                </ActionTooltip>
                            )}
                            {props.onClickEditKeywords && (
                                <ActionTooltip label={translate('apps.manage-keywords')}>
                                    <div
                                        className={"cursor-pointer flex p-1 hover:bg-green/30 transition-all rounded-md"}
                                        onClick={() => props.onClickEditKeywords && props.onClickEditKeywords(data)}>
                                        <Key className={"text-gray-dark dark:text-white"} size={15}/>
                                    </div>
                                </ActionTooltip>
                            )}
                        </div>
                    )}
                    {props.onUpdate && (
                        <ActionTooltip label={translate('apps.update-app')}>
                            <div
                                className={"cursor-pointer flex p-1 hover:bg-green/30 transition-all rounded-md"}
                                onClick={() => props.onUpdate && props.onUpdate(data)}>
                                <RefreshCwIcon
                                    className={`text-gray-dark dark:text-white ${data.loading ? 'animate-[spin_1.5s_infinite]' : ''}`}
                                    size={15}/>
                            </div>
                        </ActionTooltip>
                    )}
                    {props.onDelete && (
                        <ActionTooltip label={translate('def.delete')}>
                            <div
                                className={"cursor-pointer flex p-1 hover:bg-red/30 transition-all rounded-md"}
                                onClick={() => props.onDelete && props.onDelete(data)}>
                                <X className={"text-gray-dark dark:text-white"} size={15}/>
                            </div>
                        </ActionTooltip>
                    )}
                </div>
            </div>
            <div className={"flex flex-col gap-1 p-2"}>
                <div className={"flex flex-row gap-2"}>
                    {data.loading ?
                        <div className={"h-20 w-20 bg-gray/50 rounded-md animate-[pulse_1.5s_infinite]"}/> : (
                            <a rel="noreferrer"
                               href={`https://play.google.com/store/apps/details?id=${data.app_id}&gl=${countryApp.toLowerCase()}&hl=${languageApp.toLowerCase()}-${countryApp.toUpperCase()}`}
                               target={"_blank"}>
                                <Avatar className={`h-20 w-20 transition-all`}>
                                    <AvatarFallback delayMs={500}>
                                        {details.name.charAt(0).toUpperCase()}
                                    </AvatarFallback>
                                    <AvatarImage
                                        src={details.image_src}
                                    />
                                </Avatar>
                            </a>
                        )}

                    <div className={"flex flex-col items-start"}>
                        <div className={"flex flex-row gap-2 items-center"}>
                            {data.loading ?
                                <div className={"h-6 w-44 bg-gray/50 rounded-full animate-[pulse_1.5s_infinite]"}></div> : (
                                    <span title={details.name}
                                          className={"whitespace-nowrap truncate font-bold text-gray-dark dark:text-white"}>{details.name}
                                    </span>
                                )}
                            {languageApp && countryApp && (
                                <ActionTooltip
                                    label={translate('apps.country-app-tooltip', {locale: appNameLocale})}>
                                    <div onClick={() => props.onEditLocale && props.onEditLocale(data)}>
                                        <img
                                            className={`h-4 w-4`}
                                            alt={countryApp.toUpperCase()}
                                            src={`https://purecatamphetamine.github.io/country-flag-icons/3x2/${countryApp.toUpperCase()}.svg`}/>
                                    </div>
                                </ActionTooltip>
                            )}
                        </div>
                        {data.loading ?
                            <div className={"h-4 w-28 bg-gray/50 rounded-full animate-[pulse_1.5s_infinite]"}></div> : (
                                <span
                                    title={details.publisher}
                                    className={"text-xs whitespace-nowrap truncate font-bold text-gray"}>{details.publisher ? details.publisher : translate('error.unavailable')}
                                </span>
                            )}
                        {data.loading ?
                            <div className={"h-6 w-36 bg-gray/50 rounded-full animate-[pulse_1.5s_infinite]"}></div> : data.rating && data.rating.rating ? (
                                <div className={"flex flex-row gap-2 items-center justify-center"}>
                                    <span
                                        title={"star rating"}
                                        className={"whitespace-nowrap truncate text-gray-dark dark:text-white"}>{data.rating.rating}
                                    </span>
                                    <RateStars value={Math.floor(data.rating.rating)}/>
                                    <span
                                        title={"reviews count"}
                                        className={"whitespace-nowrap truncate text-gray-dark dark:text-white text-sm"}>{`(${data.rating.reviews})`}
                                    </span>
                                </div>
                            ) : (
                                <div className={"flex flex-row gap-2 items-center justify-center"}>
                                    <span
                                        title={"unavailable"}
                                        className={"whitespace-nowrap truncate text-gray-dark dark:text-white"}>{translate('error.unavailable')}
                                    </span>
                                </div>
                            )}
                    </div>
                </div>
                <Separator/>
                <div className={"flex flex-col gap-1 mt-4"}>
                    <div className={"flex flex-row gap-2 items-center justify-between text-xs"}>
                        <div className={"flex flex-row gap-1 text-gray/60"}>
                            <ActionTooltip label={translate('apps.app-id-description')}>
                                <Link size={12}/>
                            </ActionTooltip>
                            <span>{translate('apps.app-id')}</span>
                        </div>
                        {data.loading ?
                            <div className={"h-4 w-36 bg-gray/50 rounded-full animate-[pulse_1.5s_infinite]"}></div> : (
                                <>
                                    {data.status === 'ACTIVE' ? (
                                        <ActionTooltip label={translate('apps.open-in-market')}>
                                            <a
                                                rel="noreferrer"
                                                href={`https://play.google.com/store/apps/details?id=${data.app_id}&gl=${countryApp.toLowerCase()}&hl=${languageApp.toLowerCase()}-${countryApp.toUpperCase()}`}
                                                target={"_blank"}
                                                title={"app id"}
                                                className={"whitespace-nowrap truncate font-bold text-gray-dark dark:text-white hover:text-green/50 dark:hover:text-green/80 transition-all"}>
                                                {data.app_id ? data.app_id : translate('error.unavailable')}
                                            </a>
                                        </ActionTooltip>
                                    ) : (
                                        <span
                                            className={"whitespace-nowrap truncate font-bold text-gray-dark dark:text-white"}>{data.app_id ? data.app_id : translate('error.unavailable')}
                                        </span>
                                    )}
                                </>
                            )}
                    </div>
                    <div className={"border border-dashed border-b-transparent border-r-transparent border-l-transparent border-t-gray rounded-full"}/>
                    <div className={"flex flex-row gap-2 justify-between text-xs"}>
                        <div className={"flex flex-row gap-1 text-gray/60"}>
                            <ActionTooltip label={translate('apps.installs-description')}>
                                <Download size={12}/>
                            </ActionTooltip>
                            <span>{translate('apps.installs')}</span>
                        </div>
                        {data.loading ?
                            <div className={"h-4 w-36 bg-gray/50 rounded-full animate-[pulse_1.5s_infinite]"}></div> : (
                                <span
                                    title={"installs"}
                                    className={"whitespace-nowrap truncate font-bold text-gray-dark dark:text-white"}>{data.rating && data.rating.installs ? data.rating.installs : translate('error.unavailable')}
                                </span>
                            )}
                    </div>
                    <div className={"border border-dashed border-b-transparent border-r-transparent border-l-transparent border-t-gray rounded-full"}/>
                    <div className={"flex flex-row gap-2 justify-between text-xs"}>
                        <div className={"flex flex-row gap-1 text-gray/60"}>
                            <ActionTooltip label={translate('apps.category-description')}>
                                <Tag size={12}/>
                            </ActionTooltip>
                            <span>{translate('apps.category')}</span>
                        </div>
                        {data.loading ?
                            <div className={"h-4 w-36 bg-gray/50 rounded-full animate-[pulse_1.5s_infinite]"}></div> : (
                                <span
                                    title={"category"}
                                    className={"whitespace-nowrap truncate font-bold text-gray-dark dark:text-white"}>{details.category ? details.category : translate('error.unavailable')}
                                </span>
                            )}
                    </div>
                    <div className={"border border-dashed border-b-transparent border-r-transparent border-l-transparent border-t-gray rounded-full"}/>
                    <div className={"flex flex-row gap-2 justify-between text-xs"}>
                        <div className={"flex flex-row gap-1 text-gray/60"}>
                            <ActionTooltip label={translate('apps.content-rating-description')}>
                                <AlertTriangle size={12}/>
                            </ActionTooltip>
                            <span>{translate('apps.content-rating')}</span>
                        </div>
                        {data.loading ?
                            <div className={"h-4 w-36 bg-gray/50 rounded-full animate-[pulse_1.5s_infinite]"}></div> : (
                                <span
                                    title={"content rating"}
                                    className={"whitespace-nowrap truncate font-bold text-gray-dark dark:text-white"}>{details.content_rating ? details.content_rating : translate('error.unavailable')}
                                </span>
                            )}
                    </div>
                    <div className={"border border-dashed border-b-transparent border-r-transparent border-l-transparent border-t-gray rounded-full"}/>
                </div>
            </div>
            <div className={`flex flex-row bg-gray/10 ${data.date_installed ? 'justify-between' : 'justify-end'} items-center p-2`}>
                {data.date_installed && (
                    <ActionTooltip label={translate('apps.date-installed')}>
                        <div className={"cursor-pointer flex flex-row items-center gap-1 p-1"}>
                            <span
                                className={"text-xs font-bold text-gray-dark dark:text-white"}>{getDateByLocale(data.date_installed, language)}
                            </span>
                        </div>
                    </ActionTooltip>
                )}
                <ActionTooltip label={translate('apps.date-update')}>
                    <div className={"cursor-pointer flex flex-row items-center gap-1 p-1"}>
                        {data.loading ?
                            <div className={"h-4 w-24 bg-gray/50 rounded-full animate-[pulse_1.5s_infinite]"}></div> : (
                                <span
                                    className={"text-xs font-bold text-gray-dark dark:text-white"}>{getDateByLocale(data.date_update, language)}
                                </span>
                            )}
                    </div>
                </ActionTooltip>
            </div>
        </div>
    )
}

const ItemAppCardLoader = () => {
    return (
        <div role="status"
             className={"flex flex-col w-full rounded-md border border-gray-border dark:border-gray-dark-border"}>
            <div className={"flex flex-row bg-gray/10 justify-between items-center p-2"}>
                <div className={"h-3 w-36 bg-gray/50 rounded-full animate-[pulse_1.5s_infinite]"}></div>
            </div>
            <div className={"flex flex-row gap-2 p-2"}>
                <div className={"h-20 w-20 bg-gray/50 rounded-md animate-[pulse_1.5s_infinite]"}/>

                <div className={"flex flex-col items-start gap-1"}>
                    <div className={"flex flex-row gap-2 items-center"}>
                        <span className={"h-6 w-44 bg-gray/50 rounded-full animate-[pulse_1.5s_infinite]"}></span>
                        <span className={"h-4 w-4 bg-gray/50 rounded-full animate-[pulse_1.5s_infinite]"}></span>
                    </div>
                    <span className={"h-6 w-44 bg-gray/50 rounded-full animate-[pulse_1.5s_infinite]"}></span>
                    <span className={"h-6 w-44 bg-gray/50 rounded-full animate-[pulse_1.5s_infinite]"}></span>
                </div>
            </div>
            <div className={"flex flex-col gap-1 p-2"}>
                <Separator className={"mb-4"}/>
                <div className={"flex flex-row justify-between items-center"}>
                    <div className={"h-2 w-28 bg-gray/50 rounded-full animate-[pulse_1.5s_infinite]"}></div>
                    <div className={"h-2 w-36 bg-gray/50 rounded-full animate-[pulse_1.5s_infinite]"}></div>
                </div>
                <div className={"border border-dashed border-b-transparent border-r-transparent border-l-transparent border-t-gray rounded-full"}/>
                <div className={"flex flex-row justify-between items-center"}>
                    <div className={"h-2 w-28 bg-gray/50 rounded-full animate-[pulse_1.5s_infinite]"}></div>
                    <div className={"h-2 w-36 bg-gray/50 rounded-full animate-[pulse_1.5s_infinite]"}></div>
                </div>
                <div className={"border border-dashed border-b-transparent border-r-transparent border-l-transparent border-t-gray rounded-full"}/>
                <div className={"flex flex-row justify-between items-center"}>
                    <div className={"h-2 w-28 bg-gray/50 rounded-full animate-[pulse_1.5s_infinite]"}></div>
                    <div className={"h-2 w-36 bg-gray/50 rounded-full animate-[pulse_1.5s_infinite]"}></div>
                </div>
                <div className={"border border-dashed border-b-transparent border-r-transparent border-l-transparent border-t-gray rounded-full"}/>
                <div className={"flex flex-row justify-between items-center"}>
                    <div className={"h-2 w-28 bg-gray/50 rounded-full animate-[pulse_1.5s_infinite]"}></div>
                    <div className={"h-2 w-36 bg-gray/50 rounded-full animate-[pulse_1.5s_infinite]"}></div>
                </div>
                <div className={"border border-dashed border-b-transparent border-r-transparent border-l-transparent border-t-gray rounded-full"}/>
            </div>
            <div className={"flex flex-row bg-gray/10 justify-end items-center p-2"}>
                <div className={"h-4 w-24 bg-gray/50 rounded-full animate-[pulse_1.5s_infinite]"}></div>
            </div>
        </div>
    )
}

export {ItemAppCard, ItemAppCardLoader}
