import React from "react";
import {Route, Routes} from "react-router-dom";
import DashboardScene from "../../scenes/dashboard/DashboardScene";
import TopBar from "../navigation/TopBar";
import {NavItem, NavSubItem, SideBar} from "../navigation/SideBar";
import UpdateCenterScene from "../../scenes/servers/UpdateCenterScene";
import MachinesScene from "../../scenes/machine/MachinesScene";
import CampaignAddScene from "../../scenes/campaing/CampaignAddScene";
import NotFound from "../../scenes/error/NotFound";
import {Accessibility, Activity, AtSign, Bot, Key, LayoutDashboard, Locate, Rows, ServerCog, Smartphone, UsersIcon, Waypoints} from "lucide-react";
import {ModalProvider} from "../../providers/modal-provider";
import RequireRole from "../auth/RequireRole";
import {userRoles} from "../../config/userRoles";
import UsersScene from "../../scenes/users/UsersScene";
import ServersScene from "../../scenes/servers/ServersScene";
import UserScene from "../../scenes/users/UserScene";
import {ProxyConfigsScene} from "../../scenes/proxy/ProxyConfigsScene";
import KeywordsScene from "../../scenes/keywords/KeywordsScene";
import AppsScene from "../../scenes/app/AppsScene";
import CampaignsScene from "../../scenes/campaing/CampaignsScene";
import {ButtonToTop} from "../shared/button-to-top";
import {LocaleScene} from "../../scenes/locale/LocaleScene";
import {FaqBotApiScene} from "../../scenes/faq/FaqBotApiScene";
import {AccountsScene} from "../../scenes/account/AccountsScene";
import {ProxyListScene} from "../../scenes/proxy/ProxyListScene";
import {SmsListScene} from "../../scenes/sms-service/SmsListScene";
import {SmsServicesScene} from "../../scenes/sms-service/SmsServicesScene";
import CampaignDetailScene from "../../scenes/campaing/CampaignDetailScene";
import {MachineDetailScene} from "../../scenes/machine/MachineDetailScene";
import {AccountDetailScene} from "../../scenes/account/AccountDetailScene";
import {CampaignsUsersScene} from "../../scenes/campaing/CampaignsUsersScene";
import DashboardAdminScene from "../../scenes/dashboard/DashboardAdminScene";
import {Footer} from "../Footer";

const MainLayout: React.FC = () => {

    return (
        <div>
            <ModalProvider/>
            <div
                className="flex flex-row bg-main dark:bg-main-dark w-screen min-h-screen transition-all">
                <SideBar>
                    <NavItem icon={<LayoutDashboard size={20}/>} label={'nav.dashboard'}/>

                    <RequireRole allowedRoles={[userRoles.admin, userRoles.owner]}>
                        <NavItem icon={<LayoutDashboard size={20}/>} label={'nav.dashboard-admin'} path={"/statistic"}/>
                    </RequireRole>

                    <NavItem icon={<Activity size={20}/>} label={'nav.my-apps'} path={"/apps"}/>
                    <NavItem icon={<Key size={20}/>} label={'nav.keywords'} path={"/keywords"}/>

                    <NavItem icon={<Rows size={20}/>} label={'nav.campaigns'} path={"/campaign"}>
                        <NavSubItem label={'nav.my-campaigns'} path={"/campaign/list"}/>
                        <RequireRole allowedRoles={[userRoles.admin, userRoles.owner]}>
                            <NavSubItem label={'nav.campaigns-users'} path={"/campaign/users-list"}/>
                        </RequireRole>
                        <NavSubItem label={'nav.campaign-add'} path={"/campaign/add"}/>
                    </NavItem>

                    <RequireRole allowedRoles={[userRoles.admin, userRoles.owner]}>
                        <NavItem icon={<Accessibility size={20}/>} label={'nav.machines'} path={"/machines"}/>
                        <NavItem icon={<ServerCog size={20}/>} label={'nav.servers'} path={"/servers"}>
                            <NavSubItem label={'nav.servers-list'} path={"/servers/list"}/>
                            <NavSubItem label={'nav.update-center'} path={"/servers/update-center"}/>
                        </NavItem>
                        <NavItem icon={<Waypoints size={20}/>} label={'nav.proxy'} path={"/proxy"}>
                            <NavSubItem label={'nav.proxy-list'} path={"/proxy/list"}/>
                            <NavSubItem label={'nav.proxy-configs'} path={"/proxy/configs"}/>
                        </NavItem>
                        <NavItem icon={<Smartphone size={20}/>} label={'nav.sms-service'} path={"/sms-service"}>
                            <NavSubItem label={'nav.sms-list'} path={"/sms-service/list"}/>
                            <NavSubItem label={'nav.sms-configs'} path={"/sms-service/configs"}/>
                        </NavItem>
                        <NavItem icon={<AtSign size={20}/>} label={'nav.accounts'} path={"/accounts"}>
                            <NavSubItem label={'nav.accounts'} path={"/accounts"}/>
                            <NavSubItem label={'nav.accounts-recovery'} path={"/accounts/recovery"}/>
                            <NavSubItem label={'nav.cards'} path={"/cards"}/>
                        </NavItem>
                    </RequireRole>

                    <RequireRole allowedRoles={[userRoles.adminBot, userRoles.owner]}>
                        <NavItem icon={<Bot size={20}/>} label={'nav.faq-api'} path={"/bot-api"}/>
                    </RequireRole>

                    <RequireRole allowedRoles={[userRoles.owner, userRoles.owner]}>
                        <NavItem icon={<Locate size={20}/>} label={'nav.locales'} path={"/locales"}/>
                        <NavItem icon={<UsersIcon size={20}/>} label={'nav.users'} path={"/users"}/>
                    </RequireRole>
                </SideBar>
                <div className="flex flex-col w-full min-h-screen">
                    <TopBar/>
                    <div className={`flex-grow p-4 w-full overflow-y-auto overflow-x-hidden`}>
                        <Routes>
                            <Route path="/" element={<DashboardScene/>}/>
                            <Route path="/statistic" element={<RequireRole allowedRoles={[userRoles.admin, userRoles.owner]}
                                                                           isShowDeniedPage={true}><DashboardAdminScene/></RequireRole>}/>

                            <Route path="/apps" element={<AppsScene/>}/>

                            <Route path="/campaign/add" element={<CampaignAddScene/>}/>
                            <Route path="/campaign/list" element={<CampaignsScene/>}/>
                            <Route path="/campaign/detail" element={<CampaignDetailScene/>}/>
                            <Route path="/campaign/users-list" element={<RequireRole allowedRoles={[userRoles.admin, userRoles.owner]}
                                                                                     isShowDeniedPage={true}><CampaignsUsersScene/></RequireRole>}/>

                            <Route path="/keywords" element={<KeywordsScene/>}/>

                            <Route path="/machines" element={<RequireRole allowedRoles={[userRoles.admin, userRoles.owner]}
                                                                          isShowDeniedPage={true}><MachinesScene/></RequireRole>}/>
                            <Route path="/machines/detail" element={<RequireRole allowedRoles={[userRoles.admin, userRoles.owner]}
                                                                                 isShowDeniedPage={true}><MachineDetailScene/></RequireRole>}/>

                            <Route path="/servers/list" element={<RequireRole allowedRoles={[userRoles.admin, userRoles.owner]}
                                                                              isShowDeniedPage={true}><ServersScene/></RequireRole>}/>
                            <Route path="/servers/update-center"
                                   element={<RequireRole allowedRoles={[userRoles.admin, userRoles.owner]}
                                                         isShowDeniedPage={true}><UpdateCenterScene/></RequireRole>}/>

                            <Route path="/proxy/configs" element={<RequireRole allowedRoles={[userRoles.admin, userRoles.owner]}
                                                                               isShowDeniedPage={true}><ProxyConfigsScene/></RequireRole>}/>
                            <Route path="/proxy/list" element={<RequireRole allowedRoles={[userRoles.admin, userRoles.owner]}
                                                                            isShowDeniedPage={true}><ProxyListScene/></RequireRole>}/>

                            <Route path="/sms-service/list" element={<RequireRole allowedRoles={[userRoles.admin, userRoles.owner]}
                                                                                  isShowDeniedPage={true}><SmsListScene/></RequireRole>}/>
                            <Route path="/sms-service/configs" element={<RequireRole allowedRoles={[userRoles.admin, userRoles.owner]}
                                                                                     isShowDeniedPage={true}><SmsServicesScene/></RequireRole>}/>

                            <Route path="/locales" element={<RequireRole allowedRoles={[userRoles.owner, userRoles.owner]}
                                                                         isShowDeniedPage={true}><LocaleScene/></RequireRole>}/>

                            <Route path="/accounts" element={<RequireRole allowedRoles={[userRoles.admin, userRoles.owner]}
                                                                          isShowDeniedPage={true}><AccountsScene/></RequireRole>}/>
                            <Route path="/accounts/detail" element={<RequireRole allowedRoles={[userRoles.admin, userRoles.owner]}
                                                                                 isShowDeniedPage={true}><AccountDetailScene/></RequireRole>}/>

                            <Route path="/bot-api" element={<RequireRole allowedRoles={[userRoles.adminBot, userRoles.owner]}
                                                                         isShowDeniedPage={true}><FaqBotApiScene/></RequireRole>}/>

                            <Route path="/users" element={<RequireRole allowedRoles={[userRoles.owner]}
                                                                       isShowDeniedPage={true}><UsersScene/></RequireRole>}/>
                            <Route path="/users/detail" element={<RequireRole allowedRoles={[userRoles.owner]}
                                                                              isShowDeniedPage={true}><UserScene/></RequireRole>}/>

                            <Route path="/*" element={<NotFound/>}/>
                        </Routes>
                    </div>
                    <ButtonToTop/>
                    <Footer startYear={2019}/>
                </div>
            </div>
        </div>
    );
}

export default MainLayout;
