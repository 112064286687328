import React, {useEffect, useRef, useState} from 'react';
import {ChevronDown, ChevronLeft, MoreVertical} from 'lucide-react';

import {useSidebar} from "../../contexts/SidebarContext";

import {useLocation, useNavigate} from "react-router";
import {useLanguage} from "../../contexts/LanguageContext";
import {useModal} from "../../hooks/use-modal-store";
import {Avatar, AvatarFallback, AvatarImage} from "../shared/avatar";
import {Separator} from "../shared/separator";
import {Portal} from "../../lib/utils";
import {useAuth} from "../../contexts/AuthContext";
import {axiosPrivate} from "../../config/axios";
import {useWindowSize} from "../../hooks/use-window-size";

type SideBarProps = {
    children?: React.ReactNode;
}

const SideBar: React.FC<SideBarProps> = ({children}) => {
    const sidebar = useSidebar();

    const auth = useAuth();

    const [showSettings, setShowSettings] = useState(false);

    return (
        <aside
            id={"sidebar"}
            className={`z-50 xs:fixed sm:fixed md:fixed lg:sticky xl:sticky top-0 h-screen ${!sidebar.isExpanded ? "w-16" : "w-60"} transition-all transform ${sidebar.isExpanded || "translate-x-[-100%]"} lg:translate-x-0`}
        >
            <nav
                className="h-full flex flex-col bg-white dark:bg-main-dark border-r-[2px] border-gray-border dark:border-gray-dark-border transition-colors duration-300">
                <div className="p-4 flex justify-between items-center">
                    <div
                        className={`flex justify-items-center h-12 ${sidebar.isExpanded ? "w-32 text-xl" : "w-0 text-[0px]"} transition-all`}>
                        <img
                            src="/images/logo.png"
                            className={`flex ml-3 h-12 w-auto overflow-hidden transition-all`}
                            alt="Logo"
                        />
                        <span
                            className={`flex mt-6 -ml-3 font-bold text-gray-dark dark:text-gray whitespace-nowrap`}>edor Fun</span>
                    </div>
                    <button onClick={sidebar.toggleSidebar}
                            className="p-1 rounded-lg bg-green/20 hover:bg-green/50 dark:bg-green-dark/20 hover:dark:bg-green-dark transition-colors duration-300">
                        <ChevronLeft
                            className={`text-gray dark:text-white ${sidebar.isExpanded ? '' : 'rotate-180'} transition-all`}/>
                    </button>
                </div>

                <Separator orientation={"horizontal"} className=""/>

                <ul className="flex-1 overflow-y-scroll pt-1">
                    {children}
                </ul>

                <Separator orientation={"horizontal"} className=""/>

                <div className="flex p-3">
                    <Avatar className={`h-10 ${sidebar.isExpanded ? "w-10" : "w-0"} transition-all`}>
                        <AvatarFallback delayMs={500}>
                            {auth.user?.name?.charAt(0).toUpperCase()}{auth.user?.surname?.charAt(0).toUpperCase()}
                        </AvatarFallback>
                        <AvatarImage
                            src={auth.user?.imageSrc}
                        />
                    </Avatar>
                    <div className={`flex justify-between items-center overflow-hidden transition-all`}>
                        <div
                            className={`leading-4 overflow-hidden ${sidebar.isExpanded ? "w-52 ml-3" : "w-0 text-[0px]"} transition-all`}>
                            <h4 className="font-semibold text-gray-dark dark:text-white truncate whitespace-nowrap">{auth.user?.name} {auth.user?.surname}</h4>
                            <span className="text-xs text-gray/50 dark:text-gray">{auth.user?.email}</span>
                        </div>
                        <div
                            className="p-1.5 cursor-pointer hover:bg-green/20 rounded-md transition-all text-gray-dark dark:text-white"
                            onClick={() => setShowSettings(!showSettings)}>
                            <MoreVertical/>
                        </div>
                    </div>
                </div>
                <Settings show={showSettings} setShow={setShowSettings}/>

            </nav>
        </aside>
    );
}

type NavItemProps = {
    children?: React.ReactElement<typeof NavSubItem>[] | React.ReactElement<typeof NavSubItem>;
    icon: React.ReactNode;
    label: string;
    path?: string;
    alert?: boolean;
}

const NavItem: React.FC<NavItemProps> = ({icon, label, path = '/', alert = false, children}) => {

    const navigate = useNavigate();
    const location = useLocation();
    const {translate} = useLanguage();

    const sidebar = useSidebar();

    const [showChildren, setShowChildren] = useState(false);

    useEffect(() => {
        if (showChildren) {
            setShowChildren(false)
        }
    }, [sidebar.isExpanded]);

    const [positionTooltip, setPositionTooltip] = useState(false);
    const elementRef = useRef<HTMLDivElement>(null);

    const handleMouseOver = () => {
        if (elementRef.current) {
            setPositionTooltip(true);
        }
    }

    const handleMouseLeave = () => {
        if (elementRef.current) {
            setPositionTooltip(false);
        }
    }

    const subMenuRef = useRef<HTMLDivElement | null>(null);

    const handleShowSubMenu = () => {
        setShowChildren(!showChildren);
    };

    useEffect(() => {
        function handleClickOutside(event: MouseEvent) {
            if (subMenuRef.current && !subMenuRef.current.contains(event.target as Node) && showChildren) {
                handleShowSubMenu();
            }
        }

        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [showChildren]);

    const isActive = (currentPath: string, targetPath: string): boolean => {
        if (currentPath === "/" && targetPath === "/") {
            return true;
        }

        if (targetPath === "/" && currentPath !== "/") {
            return false;
        }

        return currentPath === targetPath || currentPath.startsWith(targetPath + "/");
    }

    const active = isActive(location.pathname, path);

    const [width] = useWindowSize();

    const handleSelectPage = () => {
        if (!children) {
            sidebar.setSelectPageName(label);
            navigate(path);
            if (width < 976 && sidebar.isExpanded) {
                sidebar.toggleSidebar(); // предполагается, что это метод для изменения состояния боковой панели
            }
        } else
            handleShowSubMenu();
    }

    return (
        <div ref={elementRef} onMouseOver={handleMouseOver} onMouseLeave={handleMouseLeave}>
            <li onClick={handleSelectPage}
                className={`relative flex items-center py-2 px-3 mx-1 my-1
                    font-medium rounded-md cursor-pointer transition-all group
                    ${active ? 'bg-green/20 text-green dark:text-white' : 'hover:bg-green/20 text-gray dark:text-white dark:hover:bg-green-dark/20'}`}>
                {icon}
                <span className={`overflow-hidden transition-all whitespace-nowrap ${
                    sidebar.isExpanded ? "w-52 ml-3" : "w-0"
                }`}>
                    {translate(label)}
                </span>

                {alert && (
                    <div
                        className={`absolute right-2 w-2 h-2 rounded bg-orange dark:bg-orange-dark transition-all ${
                            sidebar.isExpanded ? "" : "top-2"
                        }`}
                    />
                )}

                {children && (
                    <div
                        className={`absolute right-4 w-4 h-4 rounded text-gray dark:text-white transition-all top-2.5`}>
                        <ChevronDown size={sidebar.isExpanded ? 20 : 0}
                                     className={`${showChildren ? '' : '-rotate-90'} transition-all`}/>
                    </div>
                )}
            </li>
            {!sidebar.isExpanded && !children && (
                <Portal>
                    <span
                        className={`xs:hidden sm:hidden lg:block absolute text-sm shadow-md shadow-green/10 bg-white dark:bg-gray-dark font-semibold whitespace-pre text-gray-dark dark:text-white rounded-md overflow-hidden px-2 py-1 w-0 ${positionTooltip ? 'left-20 duration-300 w-fit' : 'left-0'}`}
                        style={{top: elementRef.current ? elementRef.current.getBoundingClientRect().top + (elementRef.current.getBoundingClientRect().bottom - elementRef.current.getBoundingClientRect().top) / 8 : 0}}>
                        {translate(label)}
                    </span>
                </Portal>
            )}
            {!sidebar.isExpanded && children && (
                <Portal>
                    <div
                        ref={subMenuRef}
                        className={`absolute border border-gray-dark-border overflow-hidden rounded-md bg-main dark:bg-main-dark transition-all left-16 w-fit ${showChildren ? 'block' : 'hidden'}`}
                        style={{top: elementRef.current ? elementRef.current.getBoundingClientRect().top : 0}}
                    >
                        <ul className={`flex flex-col my-1 rounded-md`}>
                            {children}
                        </ul>
                    </div>
                </Portal>
            )}

            <div
                className={`overflow-hidden ${(children && showChildren && sidebar.isExpanded) ? 'max-h-144' : 'max-h-0'} transition-all`}>
                <ul className={`flex flex-col m-2 rounded-md `}>
                    {children}
                </ul>
            </div>
        </div>
    );
}

type NavSubItemProps = {
    label: string;
    path?: string;
}

const NavSubItem: React.FC<NavSubItemProps> = ({label, path = '/'}) => {

    const navigate = useNavigate();
    const sidebar = useSidebar();
    const location = useLocation();
    const active = path === location.pathname;
    const {translate} = useLanguage();

    const [width] = useWindowSize();

    const handleSelectPage = () => {
        sidebar.setSelectPageName(label);
        navigate(path);
        if (width < 976 && sidebar.isExpanded) {
            sidebar.toggleSidebar(); // предполагается, что это метод для изменения состояния боковой панели
        }
    }

    return (
        <li onClick={handleSelectPage}
            className={`relative flex items-center py-1 px-3 mx-1 cursor-pointer transition-all rounded-md hover:bg-green/20 text-gray dark:text-white dark:hover:bg-green-dark/20`}>
            <div
                className={`h-2 w-2 mx-2 rounded-full ${active ? 'bg-green' : 'bg-gray dark:bg-white'} transition-all`}/>
            <span className={`overflow-hidden transition-all text-sm whitespace-nowrap`}>
                {translate(label)}
            </span>
        </li>
    );
}

type SettingsProps = {
    show: boolean;
    setShow: (show: boolean) => void;
}

const Settings: React.FC<SettingsProps> = ({show, setShow}) => {

    const auth = useAuth();

    const {translate} = useLanguage();
    const {onOpen} = useModal();

    const navigate = useNavigate();

    const handlerLogout = () => {
        axiosPrivate.get('/auth/logout')
            .then(r => {
                auth.setUser(null);
                auth.setApiKey(null);
            })
            .catch(e => console.log(e));

        setTimeout(() => {
            navigate('/auth');
        }, 500);
    }

    const handleShowProfile = () => {
        handleShowSettings();
        onOpen('user-profile');
    }

    const settingsRef = useRef<HTMLDivElement | null>(null);
    const [isHiding, setIsHiding] = useState(false);

    const isHidingRef = useRef(isHiding);

    useEffect(() => {
        isHidingRef.current = isHiding;
    }, [isHiding]);

    const handleShowSettings = () => {
        if (show) {
            setIsHiding(true);

            setTimeout(() => {
                if (isHidingRef.current) {
                    setIsHiding(false);
                    setShow(false);
                }
            }, 300);
        } else {
            setShow(true);
        }
    };

    useEffect(() => {
        function handleClickOutside(event: MouseEvent) {
            if (settingsRef.current && !settingsRef.current.contains(event.target as Node) && show) {
                handleShowSettings();
            }
        }

        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [show]);

    return (
        show ?
            <div
                ref={settingsRef}
                className={`absolute flex-col bottom-0 mb-2 left-full ml-2 w-full min-w-max bg-white border border-gray-border dark:border-gray-dark-border dark:bg-gray-dark rounded-xl divide-y divide-gray-border dark:divide-gray-dark-border overflow-hidden transition-all ${isHiding ? 'animate-fade-out' : 'animate-fade-in'}`}>

                <SettingsItem label={translate('nav.profile')} onClick={handleShowProfile}/>
                <SettingsItem label={translate('nav.logout')} onClick={handlerLogout}/>
            </div> : null
    )
}

type SettingsItemProps = {
    icon?: React.ReactNode;
    label: string;
    onClick?: () => void;
}

const SettingsItem: React.FC<SettingsItemProps> = ({icon, label, onClick}) => {

    return (
        <div className="flex p-2 cursor-pointer hover:bg-green/20 transition-all min-w-[150px]"
             onClick={onClick}>
            <span
                className={`text-sm font-semibold whitespace-pre text-gray-dark dark:text-white transition-all`}>
                       {label}
            </span>
        </div>
    )
}

export {SideBar, NavItem, NavSubItem};
