import React, {useEffect, useState} from "react";
import {useModal} from "../../hooks/use-modal-store";
import {Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle} from "../shared/dialog";
import {Button} from "../shared/button";
import {useLanguage} from "../../contexts/LanguageContext";
import {Separator} from "../shared/separator";
import InputChips from "../shared/input-chips";
import {AlertCircle, ImportIcon} from "lucide-react";
import useAxiosPrivate from "../../hooks/use-axios-private";

interface CheckedAccount {
    login: string;
    account_status: string;
}


export const AddAccountsTestingModal = () => {
    const {isOpen, onClose, type, callback, data} = useModal();
    const isModalOpen = isOpen && type === "add-accounts-testing";
    const {translate} = useLanguage();

    const [loading, setLoading] = useState<boolean>(false);

    // Количество требуемых фидбеков
    const [requireAccounts, setRequireAccounts] = useState<number>(0);

    // Состояние формы с фидбеками
    const [form, setForm] = useState<{ accounts: string[] }>({accounts: []});

    // Импортированные фидбеки
    const [importedAccounts, setImportedAccounts] = useState<string[]>([]);

    // Ошибки
    const [error, setError] = useState<Record<string, { value: string }>>({});

    // Загрузка данных при открытии модалки
    useEffect(() => {
        if (isModalOpen) {
            setRequireAccounts(data.count || 0);
            setTimeout(() => {
                setForm((prev) => ({
                    ...prev,
                    accounts: prev.accounts.length > 0 ? prev.accounts : (data.keywords || []),
                }));
            }, 10);
        }
    }, [isModalOpen, data.count, data.keywords]);

    const axiosPrivate = useAxiosPrivate();

    // Проверка и подтверждение фидбеков
    const handlerAddAccounts = () => {
        setError({});
        let hasError = false;

        if (form.accounts.length === 0) {
            setError(prev => ({...prev, feedbacks: {value: 'accounts.modal.one_account_is_required'}}));
            hasError = true;
        }

        // Проверка на соответствие количества фидбеков требуемому
        if (form.accounts.length !== requireAccounts) {
            setError(prev => ({...prev, feedbacks: {value: 'accounts.modal.accounts_count_mismatch'}}));
            hasError = true;
        }

        if (Object.keys(error).length > 0) {
            hasError = true;
        }

        if (hasError) {
            return;
        }

        // Передача данных обратно в основную форму
        callback?.onConfirm?.(form.accounts);
        form.accounts = [];
        onClose();
    };

    // Импорт фидбеков из файла
    const handleImportFromFile = (event: React.ChangeEvent<HTMLInputElement>) => {
        setError({});
        const file = event.target.files?.[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                const text = e.target?.result;
                if (typeof text === 'string') {
                    let importedKeywords: string[] = text.split('\n').map(keyword => keyword.trim()).filter(Boolean);

                    if (!file.name.endsWith('.txt')) {
                        setError(prev => ({...prev, file: {value: 'accounts.modal.file_must_be_txt'}}));
                    } else {
                        setImportedAccounts(importedKeywords);
                        setForm(prev => ({...prev, accounts: [...prev.accounts, ...importedKeywords].slice(0, requireAccounts)}));
                    }
                }
            };
            reader.readAsText(file);
        }
    };

    useEffect(() => {
        setError({});
        
        if (!form.accounts || form.accounts.length < 1) {
            setLoading(false);
            return;
        }
        
        setLoading(true);

        const delay = setTimeout(() => {
            axiosPrivate.post('/accounts/check', form)
                .then((response) => {
                    if (response.status === 200 && response.data.data) {
                        const checkedAccounts: CheckedAccount[] = response.data.data.checked_accounts;

                        let notFound: string[] = [];
                        let unavailable: string[] = [];

                        checkedAccounts.forEach((account: CheckedAccount) => {
                            if (account.account_status === 'TEMP') {
                                notFound.push(account.login);
                            } else if (account.account_status !== 'NONE') {
                                unavailable.push(account.login);
                            }
                        });

                        if (notFound.length > 0) {
                            let notFoundAccounts = "Account not found : " + notFound.join(", ");
                            setError(prev => ({...prev, not_found_accounts: {value: notFoundAccounts}}));
                        }

                        if (unavailable.length > 0) {
                            let unavailable_accounts = "Account unavailable : " + unavailable.join(", ");
                            setError(prev => ({...prev, unavailable_accounts: {value: unavailable_accounts}}));
                        }
                    }
                })
                .catch((error) => {
                    setError(prev => ({...prev, response: {value: 'accounts.modal.error_checking_accounts'}}));
                })
                .finally(() => {
                    setLoading(false);
                });
        }, 1000);

        return () => clearTimeout(delay); // Очищаем таймер при изменении accounts
    }, [form.accounts]);

    return (
        <Dialog open={isModalOpen}>
            <DialogContent className="bg-white text-black p-0 min-w-[800px]">
                <DialogHeader className="pt-8 px-6">
                    <DialogTitle className="text-xl text-center font-bold text-gray-dark dark:text-white">
                        {translate('accounts.modal.title')}
                    </DialogTitle>
                    <Separator/>
                </DialogHeader>
                <div className="max-h-144 p-6">
                    <div className="flex flex-col gap-4">
                        <span className={"text-gray-dark dark:text-white text-sm"}>{translate('accounts.modal.description')}</span>

                        <InputChips
                            items={form.accounts}
                            importItems={importedAccounts}
                            maxItems={requireAccounts}
                            duplicate={false}
                            placeholder={translate('accounts.modal.placeholder')}
                            onChange={(value) => setForm(prev => ({...prev, accounts: value}))}
                            className={"min-h-[200px]"}
                        />

                        <div className={"flex flex-row justify-between"}>
                            {/* Кнопка импорта */}
                            <div>
                                <Button
                                    onClick={() => document.getElementById('fileInput')?.click()}
                                    className={"bg-blue/50 hover:bg-blue/80 dark:bg-blue/30 dark:hover:bg-blue/50"}>
                                    <div className={"flex flex-row gap-2 w-full items-center justify-start"}>
                                        <ImportIcon size={16}/>
                                        <span className={"text-xs"}>{translate('accounts.modal.import')}</span>
                                    </div>
                                </Button>
                                <input
                                    id="fileInput"
                                    type="file"
                                    accept=".txt"
                                    onChange={handleImportFromFile}
                                    className="hidden"
                                />
                            </div>

                            {/* Счетчик добавленных accounts */}
                            <div className={"text-gray-dark dark:text-white flex flex-row gap-1 justify-center items-center"}>
                                <span className={form.accounts.length === requireAccounts ? "text-green" : "text-red"}>
                                    {form.accounts.length}
                                </span>
                                <span>/</span>
                                <span>{requireAccounts}</span>
                            </div>
                        </div>

                        {/* Сообщения с информацией */}
                        <div className={"flex flex-col items-start"}>
                            {/* Вывод ошибок */}
                            {Object.keys(error).length > 0 && (
                                <div className={"flex w-full flex-col gap-2 mt-2 justify-center"}>
                                    <Separator/>
                                    {Object.values(error).map((value, index) => (
                                        <div key={"errorDiv_" + index} className={"flex flex-row gap-2 text-red items-center"}>
                                            <AlertCircle size={14}/>
                                            <span key={`error_${index}`} className={"flex text-xs"}>{translate(value.value)}</span>
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                    </div>
                </div>

                {/* Кнопки управления */}
                <DialogFooter className="bg-gray-100 p-4">
                    <Button className={"bg-gray/50 hover:bg-gray/90"} onClick={onClose} disabled={loading}>
                        {translate('def.cancel')}
                    </Button>
                    <Button onClick={handlerAddAccounts} disabled={loading || Object.values(error).length > 0}>
                        {translate('def.add')}
                    </Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    );
};
