import {useModal} from "../../hooks/use-modal-store";
import {useLanguage} from "../../contexts/LanguageContext";
import React, {useEffect, useState} from "react";
import {Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle} from "../shared/dialog";
import {Separator} from "../shared/separator";
import {Button} from "../shared/button";

export const TaskTypesInfoModal = () => {
    const {isOpen, onClose, type, data} = useModal();
    const isModalOpen = isOpen && type === "task-types-info";
    const {translate} = useLanguage();

    const [taskTypes, setTaskTypes] = useState<any[]>([]);

    // Загрузка данных при открытии модалки
    useEffect(() => {
        if (isModalOpen) {
            if (data.taskTypes) {
                setTaskTypes(data.taskTypes);
            }
        }
    }, [isOpen]);

    return (
        <Dialog open={isModalOpen} onOpenChange={onClose}>
            <DialogContent className="bg-white text-black p-0 min-w-[800px]">
                <DialogHeader className="pt-8 px-6">
                    <DialogTitle className="text-xl text-center font-bold text-gray-dark dark:text-white">
                        {translate('campaigns.add.modal.types.title')}
                    </DialogTitle>
                    <Separator/>
                </DialogHeader>
                <div className="max-h-144 p-6 overflow-scroll">
                    <div className="flex flex-col gap-4">
                        {taskTypes.map((taskType, index) => (
                            <div key={"info_type_" + index} className="flex flex-col gap-2 items-center">
                                <span className={"text-lg font-bold text-gray"}>
                                    {taskType}:
                                </span>
                                <span className={"text-gray-dark dark:text-white"}>
                                    {translate(`campaigns.add.modal.types.${taskType}`)}
                                </span>
                            </div>
                        ))}
                    </div>
                </div>

                {/* Кнопки управления */}
                <DialogFooter className="bg-gray-100 p-4">
                    <Button onClick={onClose}>
                        {translate('def.ok')}
                    </Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    );
}