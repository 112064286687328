import React from "react";
import {Button} from "../../components/shared/button";
import {useNavigate} from "react-router-dom";
import {useLanguage} from "../../contexts/LanguageContext";

export const ServerUnavailable: React.FC = () => {

    const navigate = useNavigate();

    const {translate} = useLanguage();

    const handleRefresh = () => {
        navigate("/");
    }

    return (
        <div className="flex flex-col justify-center items-center h-screen bg-main dark:bg-main-dark gap-4">
            <h1 className="text-5xl font-bold text-gray-dark dark:text-white">{translate('error.server-unavailable')}</h1>
            <Button onClick={handleRefresh} className={"bg-orange/20 hover:bg-orange/50 w-fit"}>{translate('error.server-unavailable-button')}</Button>
        </div>
    )
}