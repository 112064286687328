import React, {useEffect, useState} from "react";
import {Select, SelectContent, SelectItem, SelectTrigger, SelectValue} from "../../components/shared/select";
import {ActionTooltip} from "../../components/shared/tooltip";
import {RefreshCwIcon} from "lucide-react";
import {Card} from "../../components/shared/card";
import {useLanguage} from "../../contexts/LanguageContext";
import useAxiosPrivate from "../../hooks/use-axios-private";
import {getDateByLocale} from "../../lib/utils";
import DateRangePickerPopover from "../../components/shared/date-range-picker";
import {addDays} from "date-fns";
import {ChartData, LineChart, LineData} from "../../components/shared/ag-chart";
import {LocaleData, transformJsonToLocaleDataList} from "../../lib/transform-json";

export const InstallMonitorAdmin: React.FC = () => {

    const {translate, language} = useLanguage();
    const axiosPrivate = useAxiosPrivate();

    const [loading, setLoading] = useState({
        init: true,
        statistic: true,
    });

    const chartTypes = ["SUCCESS", "VERIFY", "CAPTCHA_VERIFY", "BAN"];

    const [locales, setLocales] = useState<LocaleData[]>([]);
    const [selectedLocale, setSelectedLocale] = useState<LocaleData | null>(null);

    const [chartType, setChartType] = useState<string>(chartTypes[0]);

    const startDate = addDays(new Date(), 0);
    startDate.setHours(0, 0, 0, 0);

    const endDate = new Date();
    endDate.setHours(23, 59, 59, 999);

    const [rangeDate, setRangeDate] = useState<{ startDate: Date, endDate: Date }>({
        startDate: startDate,
        endDate: endDate
    });

    const [statisticData, setStatisticData] = useState<ChartData | null>(null);
    const [lastUpdateTime, setLastUpdateTime] = useState<string | null>('');

    const loadStatistics = (force: boolean = false) => {
        setLoading({
            ...loading,
            statistic: true
        });

        setStatisticData(null);

        axiosPrivate.post(`/statistic/admin/servers`, {
            start_date: rangeDate.startDate.toISOString(),
            end_date: rangeDate.endDate.toISOString(),
            type: chartType,
            locale: {
                country: selectedLocale?.country || '',
                language: selectedLocale?.language || ''
            },
            force_update: force
        }).then((response) => {
            if (response.data.data.categories.length > 0) {
                let responseDataCategories = response.data.data.categories;
                let responseDataSeries = response.data.data.series;

                let result: ChartData = {
                    data: [],
                    series: []
                }

                responseDataCategories.forEach((time: number) => {
                    let existingItem = result.data.find((item: LineData) => item.dateTime === time);

                    if (!existingItem) {
                        result.data.push({dateTime: time});
                        existingItem = result.data[result.data.length - 1]; // Получаем только что добавленный элемент
                    }

                    Object.keys(responseDataSeries).forEach((key) => {
                        existingItem!![key.toLowerCase().replace(' ', '_')] =
                            responseDataSeries[key].find((v: { time_check: number }) => v.time_check === time)?.value ?? 0;

                    });
                });

                result.data.forEach((item) => {
                    // Суммируем все числовые значения
                    item.total = Object.entries(item)
                        .filter(([key]) => key !== "dateTime" && key !== "total") // Исключаем `dateTime` и `total`
                        .reduce((sum, [_, value]) => sum + (value as number), 0);

                    //Добавляем серии
                    Object.keys(item).forEach((key) => {
                        if (key !== "dateTime") {
                            let existingSeries = result.series.find((series) => series.yKey === key);

                            if (!existingSeries) {
                                result.series.push({
                                    type: 'line',
                                    xKey: 'dateTime',
                                    yKey: key,
                                    yName: key.toUpperCase().replace('_', ' '),
                                    interpolation: {type: 'smooth'},
                                    visible: key === 'total',
                                });
                            }
                        }
                    });
                });

                // Устанавливаем данные
                setLastUpdateTime(response.data.data.time_update);
                setStatisticData(result);
            } else {
                setStatisticData(null);
            }

            setLoading({
                ...loading,
                statistic: false
            });

        }).catch((error) => {
            console.log(error);
            setLoading({
                ...loading,
                statistic: false
            });
        });
    }
    
    const loadLocales = () => {
        axiosPrivate.get('/locales/list').then((response) => {
            if (response.status === 200 && response.data.data) {
                const localesData = transformJsonToLocaleDataList(response.data.data)
                    .filter(locale => locale.type === 'STANDARD')
                    .sort((a, b) => a.name.localeCompare(b.name));

                setLocales(localesData);
               
            }
        }).catch((error) => {
            console.log(error);
        });
    }

    const handleRefresh = () => {
        loadStatistics(true);
    }

    useEffect(() => {
        loadStatistics();

        return () => {

        };
    }, [rangeDate, chartType, selectedLocale]);
    
    useEffect(() => {
        loadLocales();
    }, []);

    return (
        <Card>
            <div className={"flex justify-start"}>
                <span className={"font-bold text-gray-dark dark:text-white"}>{translate('dashboard.install-monitor')}</span>
            </div>

            <div className={"flex w-full xs:items-start items-center xs:flex-col sm:flex-col md:flex-row lg:flex-row justify-between gap-2"}>
                <div className={"flex md:flex-row lg:flex-row sm:flex-col xs:flex-col items-center gap-1"}>
                    <div className={"flex items-center"}>
                        <div className={"w-44"}>
                            <Select
                                disabled={loading.statistic}
                                onValueChange={(value) => setChartType(value)}
                                defaultValue={chartTypes[0]}
                            >

                                <SelectTrigger
                                    className="bg-green/50 border-0 focus:ring-0 text-gray-dark font-bold dark:text-white ring-offset-0 focus:ring-offset-0 capitalize outline-none">
                                    <SelectValue placeholder="Chart type"/>
                                </SelectTrigger>

                                <SelectContent>
                                    {Object.values(chartTypes).map((type) => (
                                        <SelectItem
                                            key={type}
                                            value={type}
                                            className="cursor-pointer font-bold capitalize hover:bg-green/40 rounded-md text-xs"
                                        >
                                            {type}
                                        </SelectItem>
                                    ))}
                                </SelectContent>
                            </Select>
                        </div>
                    </div>
                    <div className={"flex items-center"}>
                        <div className={"w-44"}>
                            <Select
                                disabled={loading.statistic}
                                value={selectedLocale?.id?.toString() || ''}
                                onValueChange={(value) => setSelectedLocale(locales.find(locale => locale.id.toString() === value) || null)}
                            >

                                <SelectTrigger
                                    className="bg-green/50 border-0 focus:ring-0 text-gray-dark font-bold dark:text-white ring-offset-0 focus:ring-offset-0 capitalize outline-none">
                                    <SelectValue placeholder="Select locale"/>
                                </SelectTrigger>

                                <SelectContent>
                                    <div className={"h-fit max-h-60 overflow-scroll"}>
                                        <SelectItem
                                            key={'all_locales'}
                                            value={'0'}
                                            className="cursor-pointer font-bold hover:bg-green/40 rounded-md text-xs"
                                        >
                                            <div className={"flex flex-row gap-2"}>
                                                <div className={"flex flex-col items-start justify-center"}>
                                                            <span
                                                                className={"max-w-[200px] whitespace-nowrap truncate"}>All</span>
                                                </div>
                                            </div>
                                        </SelectItem>
                                        {locales.map((locale) => (
                                            <SelectItem
                                                key={locale.id.toString()}
                                                value={locale.id.toString()}
                                                className="cursor-pointer font-bold hover:bg-green/40 rounded-md text-xs"
                                            >
                                                <div className={"flex flex-row gap-2"}>
                                                    <img
                                                        className={"h-4 w-6 rounded-md overflow-hidden"}
                                                        alt={locale.country.toUpperCase()}
                                                        src={`https://purecatamphetamine.github.io/country-flag-icons/3x2/${locale.country.toUpperCase()}.svg`}/>
                                                    <div className={"flex flex-col items-start justify-center"}>
                                                            <span
                                                                className={"max-w-[200px] whitespace-nowrap truncate"}>{locale.name}</span>
                                                    </div>
                                                </div>
                                            </SelectItem>
                                        ))}
                                    </div>
                                </SelectContent>
                            </Select>
                        </div>
                    </div>
                </div>
                <div
                    className={"flex flex-row items-center gap-1"}>
                    {loading.statistic ?
                        <div className={"w-20 h-2 bg-gray/50 rounded-full animate-[pulse_1.5s_infinite]"}/> :
                        <ActionTooltip label={translate('def.last-update-time')}>
                            <span className={"text-xs text-gray/50"}>{getDateByLocale(lastUpdateTime, language)}</span>
                        </ActionTooltip>
                    }
                    <ActionTooltip label={translate('def.refresh')}>
                        <div
                            onClick={handleRefresh}
                            className={"p-2 hover:bg-green/20 rounded-md text-gray-dark dark:text-white cursor-pointer relative flex items-center transition-all"}
                        >
                            <RefreshCwIcon
                                size={20}
                                className={`text-gray-dark dark:text-white cursor-pointer ${loading.statistic ? 'animate-[spin_1.5s_infinite]' : ''}`}/>
                        </div>
                    </ActionTooltip>
                </div>
            </div>

            <DateRangePickerPopover
                initDates={rangeDate}
                onApply={(start, end) => setRangeDate({startDate: start, endDate: end})}
            />

            <LineChart chartData={statisticData} loading={loading.statistic} min={0}/>
        </Card>
    )
}
