import React from "react";
import {Button} from "../../components/shared/button";
import {transformJsonToUserData} from "../../lib/transform-json";
import useAxiosPrivate from "../../hooks/use-axios-private";
import {useLanguage} from "../../contexts/LanguageContext";
import {useAuth} from "../../contexts/AuthContext";
import {useNavigate} from "react-router-dom";

export const NewUser: React.FC = () => {

    const axiosPrivate = useAxiosPrivate();
    const {translate} = useLanguage();
    const auth = useAuth();
    const navigate = useNavigate();

    const handlerCheckApprove = () => {
        axiosPrivate.get('/auth/checkToken')
            .then(response => {
                setTimeout(() => {
                    if (response.status === 200 && response.data.data) {
                        auth.setUser(transformJsonToUserData(response.data.data));
                        navigate("/", {replace: true});
                    }
                }, 1200);
            })
            .catch(error => {
                console.log(error);
            });
    }

    return (
        <div className={"flex flex-col w-screen h-screen bg-main dark:bg-main-dark items-center justify-center gap-4"}>
            <div className={"flex flex-col gap-2 items-center"}>
                <span className={"text-xl font-bold text-gray-dark dark:text-white"}>{translate('auth.account-pending')}</span>
                <span className={"text-lg font-bold text-gray-dark dark:text-white"}>{translate('auth.account-pending-text')}</span>
            </div>

            <Button className={"w-fit"} onClick={handlerCheckApprove}>{translate('auth.check-approve')}</Button>
        </div>
    );
}